
import './App.css';
import HomePage from './pages/HomePage';
import { SelectedOptionProvider } from './context/SelectedOptionContext';
import { DrawerOptionProvider } from './context/DrawerOptionContext';

function App() {
  return (
    <SelectedOptionProvider>
          <DrawerOptionProvider>
            <HomePage />
          </DrawerOptionProvider>
    </SelectedOptionProvider>
  );
}

export default App;
