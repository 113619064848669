import { createContext, useContext, useState } from "react";

const DrawerOptionContext = createContext();

export const useDrawerOption = () => useContext(DrawerOptionContext);

export const DrawerOptionProvider = ({ children }) => {
    const [drawerOption, setDrawerOption] = useState(1);
    const [isOpen, setIsOpen] = useState(false);

    const handleDrawerOption = (option) => {
        console.log("Selected drawer option:", option.value);
        setDrawerOption(option.value);
    };

    const handleIsOpen = () => {
        setIsOpen(!isOpen);
    }

    return (
        <DrawerOptionContext.Provider value={{ drawerOption, handleDrawerOption, isOpen, handleIsOpen}}>
            {children}
        </DrawerOptionContext.Provider>
    );
};