import React from 'react';
import './DrawerItem.css';
import { useLocation } from 'react-router-dom';
import { useDrawerOption } from '../../context/DrawerOptionContext';

const DrawerItem = ({ title, path }) => {
  const location = useLocation();
  const { handleIsOpen } = useDrawerOption();

  const scrollToSection = (id) => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleClick = () => {
    // Navegamos a la ruta utilizando navigate en lugar de un enlace <a>
    // navigate(path);
    scrollToSection(path);
    handleIsOpen();
  };

  // Verificamos si la ruta actual coincide con la ruta del DrawerItem
  // Si la ruta es '/', comprobamos si location.pathname es igual a path
  // Si no es '/', verificamos si la ruta actual comienza con la ruta del DrawerItem
  const isActive = path === '/' ? location.pathname === path : location.pathname.startsWith(path);

  return (
    <div className={`drawerItem ${isActive ? 'active' : ''}`} onClick={handleClick}>
      <span className="color-c drawer-item">{title}</span>
    </div>
  );
}

export default DrawerItem;
