const publicDrawerInfo = [
    {
        id: 1,
        title: "Página Principal",
        path: "inicio",
    }, 
    {
        id: 2,
        title: "Quienes Somos",
        path: "quienes-somos",
    },
    {
        id: 3,
        title: "Nuestro Trabajo",
        path: "nuestro-trabajo",
    },
    {
        id: 4,
        title: "Contáctanos",
        path: "contactanos",
    },
];

export default publicDrawerInfo;