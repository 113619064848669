import React from 'react';
import './Footer.css'; // Archivo CSS para estilizar el Footer
import { APP_COPYRIGHT } from '../../resources/data/SystemConsts';
import mexicoFlag from '../../resources/images/webp/MexicoFlag.webp';

const MyFooter = () => {
  return (
    <div className="my-footer">
      <div className="footer-content">
        <p>{APP_COPYRIGHT}</p>
        <img className='flag' src={mexicoFlag} alt="México" />
      </div>
    </div>
  );
};

export default MyFooter;