import React, { createContext, useState, useContext } from 'react';

const SelectedOptionContext = createContext();

export const useSelectedOption = () => useContext(SelectedOptionContext);

export const SelectedOptionProvider = ({ children }) => {
  const [selectedOption, setSelectedOption] = useState(1);

  const handleSelectOption = (option) => {
    console.log("Selected option:", option.value);
    setSelectedOption(option.value);
  };

  return (
    <SelectedOptionContext.Provider value={{ selectedOption, handleSelectOption }}>
      {children}
    </SelectedOptionContext.Provider>
  );
};
