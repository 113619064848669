import React from 'react'
import './GeneralContainer.css'



const GeneralContainer = ({children, isFlewRow}) => {
  const typeClass = () => {
    if(isFlewRow) {
      return 'general-container centred flex-row'
    }
    return 'general-container centred flex-column'
  }
  return (
    <div className={typeClass()}>
        <div className='content'>
        {children}
        </div>
    </div>
  )
}

export default GeneralContainer