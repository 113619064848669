import React from 'react'
import { useDrawerOption } from '../../context/DrawerOptionContext';
import DrawerItem from './DrawerItem';
import icon from '../../resources/images/menu.svg';
import logo from '../../resources/images/webp/logo-1.webp';
import publicDrawerInfo from '../../resources/data/PublicDrawerInfo';
import './Drawer.css';

const PublicDrawer = () => {
    const {isOpen, handleIsOpen} = useDrawerOption();
  
    const toggleDrawer = () => {
      handleIsOpen();
    };
  
    return (
      <div className="drawer-container">
        <div className={`overlay ${isOpen ? 'open' : ''}`} onClick={toggleDrawer}></div>
        <div className={`drawer ${isOpen ? 'open' : ''}`}>
          <button className="toggle-btn" onClick={toggleDrawer}>
            <img src={icon} className={`icon ${isOpen ? 'rotate' : ''}`} alt='Menu'/>
          </button>
          <div className="drawer-content">
            <div>
              <img src={logo} alt='myLogo' className='icon-app'/>
            </div>
            <br/>
            {
              publicDrawerInfo.map((item, index) => (
                <DrawerItem key={index} title={item.title} path={item.path} />
              ))
            }
            <div className='bottom-space'></div>
          </div>
          
          
        </div>
      </div>
    );
}

export default PublicDrawer