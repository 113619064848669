import React, { useEffect } from "react";
import PublicDrawer from "./components/PublicDrawer";
import MainPage from "./MainPage";
import GeneralContainer from "./components/GeneralContainer";
import { useDrawerOption } from "../context/DrawerOptionContext";
import logo1 from "../resources/images/webp/logo-1.webp";
import mail from "../resources/images/mail.svg";
import call from "../resources/images/call.svg";
import whatsapp from "../resources/images/whatsapp.svg";
import facebook from "../resources/images/facebook.svg";
import "./HomePage.css";
import "./BaseSystemPage.css";
import Slideshow from "./components/Slideshow";

const HomePage = () => {
  const { isOpen } = useDrawerOption();

  useEffect(() => {
    // Agregar o quitar la clase 'lock-scroll' al body dependiendo del valor de isOpen
    if (isOpen) {
      document.body.classList.add("lock-scroll");
    } else {
      document.body.classList.remove("lock-scroll");
    }
  }, [isOpen]);

  const handleContactItemClick = (url) => {
    window.open(url, "_blank");

  };

  const CONTACT_URLS = [
    "https://wa.me/527471550463",
    "https://www.facebook.com/profile.php?id=61561187925701",

  ];

  return (
    <div>
      <PublicDrawer />
      <MainPage>
        <div className="space-div" id="inicio"></div>
        <img alt="STAFF Contadores" src={logo1} className="home-icon" />
        <br />
        <br />
        <h1 className="h1">
          Servicios Tributarios, Administrativos Fiscales y Financieros
        </h1>
        <br />
        <br />
        <Slideshow />
        <div className="left-buttons"></div>

        <div className="welcome-container" id="quienes-somos">
          <h2 className="h2">Bienvenido</h2>
          <p>
            El equipo de STAFF Contadores se encuentra comprometido con
            brindarte un servicio de calidad, fusionando las maravillas del
            trabajo remoto con la cercanía de una firma de contadores
            tradicional.
          </p>
        </div>

        <br />
        <br />
        <h2 className="h2">Nuestros Servicios</h2>
        <div className="services-container">
          <div>
          Realizamos un diagnóstico inicial de tus actividades comerciales, para diseñar un plan estratégico e integral de servicios, que se van adaptando a la perfección a las necesidades cambiantes de tu negocio. Nuestros planes pueden incluir, entre muchos otros servicios los siguientes:
          </div>
          <br />
          <br />
          <div className="left">
            <h3 className="h3">Contables</h3>
            <div>
              <ul>
                <li>Elaboración de Estados Financieros</li>
                <li>Análisis de Estados Financieros</li>
                <li>Elaboración y timbrado de facturas (CFDI)</li>
                <li>Control de inventarios</li>
                <li>Contabilidad Gubernamental</li>
              </ul>
            </div>
          </div>
          <div className="right">
            <h3 className="h3">Fiscales</h3>
            <div>
              <ul>
                <li>Impuestos federales y estatales</li>
                <li>Presentación de declaraciones</li>
                <li>Solicitud de saldos a favor</li>
                <li>Cuotas obrero-patronales</li>
                <li>Atención a requerimientos</li>
                <li>Registro de obras públicas (SIROC)</li>
                <li>Asesoría fiscal en General</li>
              </ul>
            </div>
          </div>
          <div className="left">
            <h3 className="h3">De Recursos Humanos</h3>
            <div>
              <ul>
                <li>Elaboración y timbrado de nóminas</li>
                <li>Aplicación de Incidencias</li>
                <li>Cálculo de finiquitos y liquidaciones</li>
                <li>Servicios especializados (REPSE)</li>
              </ul>
            </div>
          </div>
          <div className="right">
            <h3 className="h3">Administrativos</h3>
            <div>
              <ul>
                <li>Elaboración de expedientes</li>
                <li>Implementación de control interno</li>
                <li>Apoyo administrativo en general</li>
                <li>Apoyo en licitaciones públicas</li>
              </ul>
            </div>
          </div>
        </div>

        <br />
        <br />

        <h2 className="h2" id="nuestro-trabajo">
          Nuestro Trabajo
        </h2>
        <GeneralContainer isFlewRow={false}>
        <div className="principles-container">
            <h4 className="h4">Nuestros Clientes</h4>
            <p>
            Tenemos experiencia en una gran variedad de negocios, giros y actividades comerciales que van desde Constructoras, Restaurantes, Comercializadoras y Hoteles hasta estéticas, tiendas de abarrotes, repartidores de comida, ventas en línea, prestadores de servicios profesionales y asalariados
            </p>
          </div>
          <br />
          <div className="principles-container">
            <h4 className="h4">Colaboradores</h4>
            <p>
              Contamos con un equipo de contadores expertos en brindar asesoría
              a todo tipo de negocios, con la finalidad de fortalecer sus áreas
              Administrativas, de Nóminas, Facturación, Contabilidad e
              Impuestos.
            </p>
          </div>
          <br />
          <div className="principles-container">
            <h4 className="h4">Política de Actualización Constante</h4>
            <p>
              Conscientes de que el mundo de los negocios es un ente cambiante,
              creamos la política de actualización constante y permanente, la
              cuál garantiza que nuestro equipo de contadores se mantengan al
              tanto de las nuevas tendencias y cambios en materia contable y
              fiscal.
            </p>
          </div>
        </GeneralContainer>
        <div className="space-div"></div>
        <h2 className="h2" id="contactanos">Contáctanos</h2>
        <div className="services-container">
          
          <div className="contact-item">
            <h3>Teléfono</h3>
            <img alt="call" src={call} className="contact-icon" />
            <p><a href="tel:7471550463">747 155 0463</a></p>
            
          </div>

          <div className="contact-item">
            <h3>Correo Electrónico</h3>
            <img alt="email" src={mail} className="contact-icon" />
            <p><a href="mailto:contacto@staffcontadores.com">contacto@staffcontadores.com</a></p>
            

          </div>

          <h3 className="h2">Redes Sociales</h3>

          <div className="contact-item" onClick={() => {handleContactItemClick(CONTACT_URLS[0])}}>
            <h3>WhatsApp</h3>
            <img alt="whatsapp" src={whatsapp} className="contact-icon" />
            <p>747 155 0463</p>
            
          </div>

          <div className="contact-item" onClick={() => {handleContactItemClick(CONTACT_URLS[1])}}>
            <h3>Facebook</h3>
            <img alt="facebook" src={facebook} className="contact-icon" />
            <p>STAFF Contadores</p>
            

          </div>
          
        </div>
      </MainPage>
    </div>
  );
};

export default HomePage;
